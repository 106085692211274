function paymentTypeToDisplay(val: string | null) {
  switch (val) {
    case 'CASH': {
      val = 'เงินสด';
      break;
    }
    case 'TRANSFER': {
      val = 'โอน';
      break;
    }
    default: {
      val = '-';
      break;
    }
  }
  return val;
}

function appTypeToDisplay(val: string | null | undefined) {
  switch (val) {
    case 'STUDY': {
      val = 'สมัครเรียน';
      break;
    }
    case 'SUB_STUDY': {
      val = 'เรียนเสริม';
      break;
    }
    case 'TRAINING': {
      val = 'สมัครอบรม';
      break;
    }
    default: {
      val = '-';
      break;
    }
  }
  return val;
}

function applyThroughToDisplay(val: string | null | undefined) {
  switch (val) {
    case 'WALK_IN': {
      val = 'Walk In';
      break;
    }
    case 'LINE': {
      val = 'Line';
      break;
    }
    case 'FACEBOOK': {
      val = 'Facebook';
      break;
    }
    case 'RECOMMENDED_FRIENDS': {
      val = 'เพื่อนแนะนำ';
      break;
    }
    default: {
      val = '-';
      break;
    }
  }
  return val;
}

function carTypeToDisplay(val: string | null | undefined) {
  switch (val) {
    case 'CAR': {
      val = 'รถยนต์';
      break;
    }
    case 'MOTORCYCLES': {
      val = 'จักรยานยนต์';
      break;
    }
    default: {
      val = '-';
      break;
    }
  }
  return val;
}

function transmissionTypeToDisplay(val: string | null | undefined) {
  switch (val) {
    case 'AUTO': {
      val = 'ออโต้';
      break;
    }
    case 'MANUAL': {
      val = 'ธรรมดา';
      break;
    }
    default: {
      val = '-';
      break;
    }
  }
  return val;
}

function groupIdToText(groupId: number) {
  let groupName = '-';
  switch (groupId) {
    case 1: {
      groupName = 'Admin';
      break;
    }
    case 2: {
      groupName = 'อาจารย์';
      break;
    }
    case 3: {
      groupName = 'Operation';
      break;
    }
  }
  return groupName;
}

function statusToText(status: string = '') {
  let statusText = '-';
  switch (status) {
    case 'ACTIVE': {
      statusText = 'เปิดใช้งาน';
      break;
    }
    case 'INACTIVE': {
      statusText = 'ปิดใช้งาน';
      break;
    }
  }
  return statusText;
}

export default {
  paymentTypeToDisplay,
  appTypeToDisplay,
  applyThroughToDisplay,
  carTypeToDisplay,
  transmissionTypeToDisplay,
  groupIdToText,
  statusToText
};
