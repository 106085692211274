import { httpClient } from '../utils/HttpClient';

const BASE_URL_APPLICATION_SCHEDULE = 'api/application-schedule-teacher';
const BASE_URL_STAMP_SCHEDULE = 'api/stamp';

interface IAppScheduleTeacherParam {
  instructorId: number | string;
  scheduleDate: number | string | Date;
}

const getAppScheduleTeacherList = (filterParam: IAppScheduleTeacherParam) => {
  return httpClient
    .get(BASE_URL_APPLICATION_SCHEDULE, { params: { ...filterParam } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

interface IStampTeacher {
  id: number;
  mode: string;
  stampDateTime: string;
}

const postStampCheckTeacher = (value: IStampTeacher) => {
  return httpClient
    .post(BASE_URL_STAMP_SCHEDULE, value)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default {
  getAppScheduleTeacherList,
  postStampCheckTeacher
};
