import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import DashboardLayout from './layouts/DashboardLayout';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthorizationLayout from './layouts/AuthorizationLayout';
import NotFoundPage from './pages/errorPage/NotFoundPage';
import { StoreContextProvider } from './context/Store';
import AuthService from './services/auth.service';

if (process.env.NODE_ENV === 'production') {
  console.log = function no_console() {};
}

const App = () => {
  const authGuardDashboard = (Component: any) => (props: any) => {
    return localStorage.getItem('token') ? (
      <StoreContextProvider>
        <Component {...props} />
      </StoreContextProvider>
    ) : (
      <Redirect to="/authorization/login" />
    );
  };
  const authGuardAuthorization = (Component: any) => (props: any) => {
    return localStorage.getItem('token') ? (
      <Redirect to="/" />
    ) : (
      <Component {...props} />
    );
  };
  const userGroupId = AuthService.getCurrentUserGroup();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Switch>
        <Route path="/app" render={authGuardDashboard(DashboardLayout)} />
        <Route
          path="/authorization"
          render={authGuardAuthorization(AuthorizationLayout)}
        />
        <Route exact path="/">
          {userGroupId === 1 && <Redirect to="/app/app-for-driving-lessons" />}
          {userGroupId === 2 && <Redirect to="/app/teaching-schedule" />}
          {userGroupId === 3 && <Redirect to="/app/app-for-driving-lessons" />}
          {!userGroupId && <Redirect to="/app" />}
        </Route>
        <Route component={NotFoundPage} />
      </Switch>
      {/*<DashboardLayout />*/}
    </ThemeProvider>
  );
};

export default App;
