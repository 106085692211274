import { httpClient } from '../utils/HttpClient';
import { removeEmptyObject } from '../utils/removeEmptyObject';

const BASE_URL_MANAGE_USER = 'api/admin/manage/user';
const BASE_URL_REGISTER = 'register';
// const BASE_URL_USER_PROFILE = 'api/profile';
const BASE_URL_USER_PAGING = 'api/admin/manage/user-paging';
const BASE_URL_USER_CHANGE_PASSWORD = 'api/change-password';
const BASE_URL_EXPORT_USER = 'api/excel/report/excel-users-report';

export interface IFilterUserList {
  firstName: string;
  lastName: string;
  groupId: number | string;
  username: string;
  phone: string;
  email: string;
}

interface IPagination {
  page: number;
  size: number;
}

const getUserList = (param: IPagination, filter: IFilterUserList) => {
  let getFilter = {};
  if (filter) {
    getFilter = removeEmptyObject(filter);
  }
  const setParam = {
    page: param.page,
    size: param.size,
    ...getFilter
  };
  return httpClient
    .get(BASE_URL_USER_PAGING, { params: { ...setParam } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const getUserById = (userId: number) => {
  return httpClient
    .get(`${BASE_URL_MANAGE_USER}/${userId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export interface IUserForm {
  firstName: string;
  lastName: string;
  groupId: number | string;
  username: string;
  phone: string;
  email: string | null | undefined;
  password?: string;
  passwordConfirmation?: string;
  status: string;
  car: boolean;
  motorcycle: boolean;
}

const postUser = (value: IUserForm | undefined) => {
  return httpClient
    .post(BASE_URL_REGISTER, value)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const updateUser = (value: IUserForm | undefined, userId: number) => {
  return httpClient
    .put(`${BASE_URL_MANAGE_USER}/${userId}`, value)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export interface IUserPasswordForm {
  password: string;
  passwordConfirmation: string;
}

const updatePassword = (
  value: IUserPasswordForm | undefined,
  userId: number
) => {
  return httpClient
    .put(`${BASE_URL_USER_CHANGE_PASSWORD}/${userId}`, value)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const deleteUser = (id: number) => {
  return httpClient
    .delete(`${BASE_URL_MANAGE_USER}/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const exportUserList = (filter: IFilterUserList) => {
  let getFilter = {};
  if (filter) {
    getFilter = removeEmptyObject(filter);
  }
  return httpClient
    .get(`${BASE_URL_EXPORT_USER}`, {
      responseType: 'arraybuffer',
      params: { ...getFilter }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default {
  getUserList,
  getUserById,
  postUser,
  updateUser,
  updatePassword,
  deleteUser,
  exportUserList
};
