import { httpClient } from '../utils/HttpClient';

const BASE_URL_MONTHLY_SUMMARY = 'api/report/monthly-summary';

interface IFilterSummary {
  year: number | string | null;
  month: number | string | null;
}

const getMonthlySummaryList = (filterParam: IFilterSummary) => {
  return httpClient
    .get(BASE_URL_MONTHLY_SUMMARY, { params: { ...filterParam } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default {
  getMonthlySummaryList
};
