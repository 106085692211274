import { httpClient } from '../utils/HttpClient';

const BASE_URL_PROVINCE = 'api/provinces';

const getProvinceOption = () => {
  return httpClient.get(BASE_URL_PROVINCE).then((response) => {
    // console.log('province', response);
    const mapProvinceOption: any[] | PromiseLike<any[]> = [];
    if (response && response.data.length > 0) {
      response.data.forEach((item: any) => {
        mapProvinceOption.push({
          value: item.id,
          label: item.nameTh
        });
      });
    }
    return mapProvinceOption;
  });
};

export default {
  getProvinceOption
};
