import { httpClient } from '../utils/HttpClient';
import { removeEmptyObject } from '../utils/removeEmptyObject';

const BASE_URL_APPLICATION = 'api/application';

interface IPagination {
  page: number;
  size: number;
}

// function removeEmpty(obj: { [x: string]: any }) {
//   Object.keys(obj).forEach(function (key) {
//     (obj[key] && typeof obj[key] === 'object' && removeEmpty(obj[key])) ||
//       ((obj[key] === '' || obj[key] === null) && delete obj[key]);
//   });
//   return obj;
// }

const getListApplicationDriving = (param: IPagination, filter: any) => {
  let getFilter = {};
  if (filter) {
    getFilter = removeEmptyObject(filter);
  }
  const setParam = {
    page: param.page,
    size: param.size,
    ...getFilter
  };
  return httpClient
    .get(`${BASE_URL_APPLICATION}-paging`, { params: { ...setParam } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const getApplicationDrivingById = (id: number) => {
  return httpClient
    .get(`${BASE_URL_APPLICATION}/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const postApplicationDriving = (value: any) => {
  return httpClient
    .post(BASE_URL_APPLICATION, value)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const deleteApplicationDriving = (id: number) => {
  return httpClient
    .delete(`${BASE_URL_APPLICATION}/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const updateApplicationDriving = (value: any, id: any) => {
  return httpClient
    .put(`${BASE_URL_APPLICATION}/${id}`, value)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default {
  getListApplicationDriving,
  getApplicationDrivingById,
  postApplicationDriving,
  deleteApplicationDriving,
  updateApplicationDriving
};
