import { httpClient } from '../utils/HttpClient';

const BASE_URL_INSTRUCTORS = 'api/instructors';

export interface IFilterInstructor {
  scheduleDate?: Date | undefined | number;
  carType?: 'CAR' | 'MOTORCYCLES' | string;
  scheduleTimeSlotId?: number | string | null | undefined;
  scheduleType?: number | string | null | undefined;
}

const getInstructorsOption = (filterParam?: IFilterInstructor) => {
  return httpClient
    .get(BASE_URL_INSTRUCTORS, { params: filterParam })
    .then((response) => {
      const mapInstructorsOption: any[] | PromiseLike<any[]> = [];
      if (response && response.data.length > 0) {
        response.data.forEach((item: any) => {
          mapInstructorsOption.push({
            value: item.userId,
            label: item.firstName + ' ' + item.lastName
          });
        });
      }
      return mapInstructorsOption;
    });
};

const getInstructorsWithCountOption = (filterParam: IFilterInstructor) => {
  return httpClient
    .get(`${BASE_URL_INSTRUCTORS}-count`, { params: filterParam })
    .then((response) => {
      const mapInstructorsOption: any[] | PromiseLike<any[]> = [];
      if (response && response.data.length > 0) {
        response.data.forEach((item: any) => {
          mapInstructorsOption.push({
            value: item.userId,
            label: `${item.firstName} ${item.lastName} (${
              item.count && item.count.countAll ? item.count.countAll : 0
            } ชั่วโมง)`
          });
        });
      }
      return mapInstructorsOption;
    });
};

export default {
  getInstructorsOption,
  getInstructorsWithCountOption
};
