import { httpClient } from '../utils/HttpClient';
import { removeEmptyObject } from '../utils/removeEmptyObject';

const BASE_URL_APPLICATION_EXAM = 'api/application-exam';
const BASE_URL_APPLICATION_EXAM_EXPORT = 'api/excel/application-exam';

interface IPagination {
  page: number;
  size: number;
}

export interface IFilterAppExam {
  examDateFrom: number;
  examDateTo: number;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  carType: string;
  appType: string;
  transmissionType: string;
  applyThrough: string;
}

const getApplicationExamList = (
  pagination: IPagination,
  filter: IFilterAppExam
) => {
  let getFilter = {};
  if (filter) {
    getFilter = removeEmptyObject(filter);
  }
  const setParam = {
    page: pagination.page,
    size: pagination.size,
    ...getFilter
  };
  return httpClient
    .get(`${BASE_URL_APPLICATION_EXAM}/paging`, { params: { ...setParam } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const exportExcelAppScheduleExam = (filter: IFilterAppExam) => {
  let getFilter = {};
  if (filter) {
    getFilter = removeEmptyObject(filter);
  }
  return httpClient
    .get(`${BASE_URL_APPLICATION_EXAM_EXPORT}`, {
      responseType: 'arraybuffer',
      params: { ...getFilter }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default {
  getApplicationExamList,
  exportExcelAppScheduleExam
};
