import registerDrivingFormModel from './registerDrivingFormModel';

const {
  formField: {
    appType,
    applyThrough,
    carType,
    transmissionType,
    prefixName,
    firstName,
    lastName,
    nickName,
    age,
    birthOfDate,
    idCardNumber,
    houseNumber,
    moo,
    road,
    province,
    amphoe,
    tambon,
    zipCode,
    mobileNumber,
    email,
    idLine,
    contractFullName,
    contractMobileNumber,
    totalPrice,
    discount,
    paymentMethod,
    amountPaid,
    image: { fileData, fileName },
    courseHour
  }
} = registerDrivingFormModel;

export default {
  [appType.name]: '',
  [applyThrough.name]: '',
  [carType.name]: '',
  [transmissionType.name]: '',
  [prefixName.name]: '',
  [firstName.name]: '',
  [lastName.name]: '',
  [nickName.name]: '',
  [age.name]: '',
  [birthOfDate.name]: '',
  [idCardNumber.name]: '',
  [houseNumber.name]: '',
  [moo.name]: '',
  [road.name]: '',
  [province.name]: '',
  [amphoe.name]: '',
  [tambon.name]: '',
  [zipCode.name]: '',
  [mobileNumber.name]: '',
  [email.name]: '',
  [idLine.name]: '',
  [contractFullName.name]: '',
  [contractMobileNumber.name]: '',
  [totalPrice.name]: '',
  [discount.name]: '',
  [paymentMethod.name]: '',
  [amountPaid.name]: '',
  image: {
    [fileName.name]: '',
    [fileData.name]: ''
  },
  [courseHour.name]: ''
};
