import React from 'react';
import {
  Breadcrumbs,
  Container,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Link as RouterLink, Route } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import routes from '../routes';

const useStyles = makeStyles(() => ({
  breadcrumbFont: {
    fontFamily: "'Mitr', sans-serif"
  }
}));

const BreadcrumbsBar = () => {
  const LinkRouter = (props: any) => <Link {...props} component={RouterLink} />;
  const classes = useStyles();

  return (
    <Container maxWidth={false}>
      <Route>
        {(props: any) => {
          const crumbs = routes
            .filter(({ href }: any) => props.match.path.includes(href))
            .map(({ href, ...rest }: any) => ({
              path: Object.keys(props.match.params).length
                ? Object.keys(props.match.params).reduce(
                    (path, param) =>
                      path.replace(`:${param}`, props.match.params[param]),
                    href
                  )
                : href,
              ...rest
            }));
          // console.log(`Generated crumbs for ${props.match.path}`);
          // crumbs.map(({ title, path }: any) => console.log({ title, path }));
          // if (crumbs.length <= 1) {
          // return null;
          // }
          return (
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="Breadcrumb"
            >
              {crumbs.map(({ title, path, isParent }: any, key) => {
                return key + 1 === crumbs.length ? (
                  <Typography
                    color="textPrimary"
                    key={key}
                    className={classes.breadcrumbFont}
                  >
                    {title}
                  </Typography>
                ) : (
                  <div key={key}>
                    {isParent ? (
                      <Typography
                        color="textPrimary"
                        key={key}
                        className={classes.breadcrumbFont}
                      >
                        {title}
                      </Typography>
                    ) : (
                      <LinkRouter
                        color="inherit"
                        to={path}
                        key={key}
                        className={classes.breadcrumbFont}
                      >
                        {title}
                      </LinkRouter>
                    )}
                  </div>
                );
              })}
            </Breadcrumbs>
          );
        }}
      </Route>
    </Container>
  );
};

export default BreadcrumbsBar;

// <Route>
//   {({ location }) => {
//     // console.log('BreadcrumbsBar', location)
//     const pathNames = location.pathname
//       .split('/')
//       .filter((x) => x && x !== 'app');
//     return (
//       <Breadcrumbs
//         separator={<NavigateNextIcon fontSize="small" />}
//         aria-label="Breadcrumb"
//       >
//         <LinkRouter color="inherit" component={RouterLink} to="/">
//           Home
//         </LinkRouter>
//         {pathNames.map((value, index) => {
//           const last = index === pathNames.length - 1;
//           const to = `/app/${pathNames.slice(0, index + 1).join('/')}`;
//
//           // Split value so the string can be transformed and parsed later.
//           const path = value.split('-');
//           // Convert first char of string to uppercase.
//           path.forEach((item, i) => {
//             // Only capitalize starting from the second element.
//             if (i > 0) {
//               path[i] =
//                 path[i].charAt(0).toUpperCase() + path[i].slice(1);
//             }
//           });
//
//           return last || index === 0 ? (
//             <Typography color="textPrimary" key={to}>
//               {/*{translate(path.join(""))}*/}
//               {path.join('')}
//             </Typography>
//           ) : (
//             <LinkRouter color="inherit" to={to} key={to}>
//               {/*{translate(path.join(""))}*/}
//               {path.join('')}
//             </LinkRouter>
//           );
//         })}
//       </Breadcrumbs>
//     );
//   }}
// </Route>
