import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Avatar,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AuthService from '../../services/auth.service';
import theme from '../../theme';
import Logo from '../../components/Logo';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 32,
    height: 32,
    marginRight: 8,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0
    }
  },
  topBarLogo: {
    width: 110,
    height: 55
  },
  topBarLogoMobile: {
    width: 100,
    height: 40
  },
  topBarLogoName: {
    color: 'white',
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: 18,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  topBarGroupLogo: {
    display: 'flex',
    alignItems: 'center'
  },
  profileMenu: {
    minWidth: 150
  },
  profileMenuHeader: {
    marginTop: theme.spacing(6.5)
  }
}));

interface ITopBar {
  className?: string;
  onMobileNavOpen?: any;
}

const TopBar = ({ className, onMobileNavOpen, ...rest }: ITopBar) => {
  const history = useHistory();
  const classes = useStyles();
  const user = AuthService.getCurrentUserProfile();
  const onLogout = () => {
    AuthService.logout();
    history.push('/authorization/login', { replace: true });
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const logo = (logoClass: string) => {
    return <Logo className={logoClass} />;
  };
  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        {/* @ts-ignore */}
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        {/* @ts-ignore */}
        <Hidden mdDown>
          <RouterLink to="/">
            {/*<IconButton*/}
            {/*  color="inherit"*/}
            {/*  className={classes.removePadding}*/}
            {/*>*/}
            {/*  */}
            {/*</IconButton>*/}
            <div className={classes.topBarGroupLogo}>
              {logo(classes.topBarLogo)}
              <Typography variant="h6" className={classes.topBarLogoName}>
                Premium driving
              </Typography>
            </div>
          </RouterLink>
        </Hidden>
        {/* @ts-ignore */}
        <Hidden lgUp>
          <Box flexGrow={1} />
          {logo(classes.topBarLogoMobile)}
        </Hidden>
        <Box flexGrow={1} />
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar className={classes.avatar} src={user?.avatar} />
            {/* @ts-ignore */}
            <Hidden mdDown>
              <Typography variant="h6">{user?.name}</Typography>
            </Hidden>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={open}
            onClose={handleClose}
            classes={{ paper: classes.profileMenu }}
            className={classes.profileMenuHeader}
          >
            {/*<MenuItem onClick={() => history.push('/app/profile')}>*/}
            {/*  Profile*/}
            {/*</MenuItem>*/}
            <MenuItem onClick={onLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
