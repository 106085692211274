import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import EventNoteIcon from '@material-ui/icons/EventNote';
import StorageIcon from '@material-ui/icons/Storage';
import BarChartIcon from '@material-ui/icons/BarChart';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import BallotIcon from '@material-ui/icons/Ballot';
import { User as UserIcon } from 'react-feather';

interface INavbarMenu {
  href: string;
  icon: any;
  title: string;
  layout: string;
  groupId: number[];
  isChild?: boolean;
  children?: INavbarMenu[];
}

// groupId 1 = admin
// groupId 2 = teacher
// groupId 3 = operation

const navbarMenu: INavbarMenu[] = [
  {
    href: '/app/app-for-driving-lessons',
    icon: ReceiptOutlinedIcon,
    title: 'ใบสมัคร',
    layout: '/app',
    groupId: [1, 3]
  },
  {
    href: '/app/teacher-schedule-list',
    icon: EventNoteIcon,
    title: 'ตารางการเรียนการสอน',
    layout: '/app',
    groupId: [1, 3],
    children: [
      {
        href: '/app/teacher-schedule-list/car',
        icon: null,
        title: 'รถยนต์',
        layout: '/app',
        groupId: [1, 3],
        isChild: true
      },
      // {
      //   href: '/app/teacher-schedule-list/sub-study-car',
      //   icon: null,
      //   title: 'เรียนเสริมรถยนต์',
      //   layout: '/app',
      //   groupId: [1, 3],
      //   isChild: true
      // },
      {
        href: '/app/teacher-schedule-list/motor',
        icon: null,
        title: 'รถจักรยานยนต์',
        layout: '/app',
        groupId: [1, 3],
        isChild: true
      },
      {
        href: '/app/teacher-schedule-list/sub-study-motor',
        icon: null,
        title: 'เรียนเสริมรถจักรยานยนต์',
        layout: '/app',
        groupId: [1, 3],
        isChild: true
      }
    ]
  },
  {
    href: '/app/exam-schedule',
    icon: BallotIcon,
    title: 'ตารางสอบ',
    layout: '/app',
    groupId: [1, 3]
  },
  {
    href: '/app/report',
    icon: BarChartIcon,
    title: 'รายงาน',
    layout: '/app',
    groupId: [1],
    children: [
      {
        href: '/app/report/recruitment-driving-lesson-report',
        icon: null,
        title: 'รายงานการรับสมัคร',
        layout: '/app',
        groupId: [1],
        isChild: true
      },
      {
        href: '/app/report/payment-report',
        icon: null,
        title: 'รายงานการชำระเงิน',
        layout: '/app',
        groupId: [1],
        isChild: true
      },
      {
        href: '/app/report/teaching-hours-report',
        icon: null,
        title: 'รายงานจำนวนชั่วโมงสอน',
        layout: '/app',
        groupId: [1],
        isChild: true
      }
    ]
  },
  // {
  //   href: '/app/master-data',
  //   icon: StorageIcon,
  //   title: 'ข้อมูลหลัก',
  //   layout: '/app',
  //   groupId: [1],
  //   children: [
  //     {
  //       href: '/app/master-data/car-list',
  //       icon: null,
  //       title: 'ข้อมูลรถ',
  //       layout: '/app',
  //       groupId: [1],
  //       isChild: true
  //     },
  //     {
  //       href: '/app/master-data/course-list',
  //       icon: null,
  //       title: 'ข้อมูลหลักสูตร',
  //       layout: '/app',
  //       groupId: 1,
  //       isChild: true
  //     }
  //   ]
  // },
  {
    href: '/app/teaching-schedule',
    icon: ScheduleIcon,
    title: 'ตารางสอน',
    layout: '/app',
    groupId: [2]
  },
  {
    href: '/app/monthly-summary',
    icon: AssignmentTurnedInIcon,
    title: 'Monthly Summary',
    layout: '/app',
    groupId: [2]
  },
  {
    href: '/app/user-list',
    icon: AssignmentIndOutlinedIcon,
    title: 'รายชื่อผู้ใช้',
    layout: '/app',
    groupId: [1]
  },
  {
    href: '/app/profile',
    icon: UserIcon,
    title: 'ข้อมูลส่วนตัว',
    layout: '/app',
    groupId: [1, 2, 3]
  }
];

export default navbarMenu;

// const routes: any[] = [
//   {
//     href: '/app/dashboard',
//     icon: BarChartIcon,
//     title: 'Dashboard'
//   },
//   {
//     href: '/app/customers',
//     icon: UsersIcon,
//     title: 'Customers'
//   },
//   {
//     href: '/app/products',
//     icon: ShoppingBagIcon,
//     title: 'Products'
//   },
//   {
//     href: '/app/account',
//     icon: UserIcon,
//     title: 'Account'
//   },
//   {
//     href: '/app/settings',
//     icon: SettingsIcon,
//     title: 'Settings'
//   },
//   {
//     href: '/login',
//     icon: LockIcon,
//     title: 'Login'
//   },
//   {
//     href: '/register',
//     icon: UserPlusIcon,
//     title: 'Register'
//   },
//   {
//     href: '/app/user-manage',
//     icon: UserPlusIcon,
//     title: 'User manage',
//     children: [
//       {
//         href: '/app/user-manage/user-list',
//         // icon: UserPlusIcon,
//         title: 'User list',
//         isChild: true
//       }
//     ]
//   },
//   {
//     href: '/user-admin',
//     icon: UserPlusIcon,
//     title: 'User admin',
//     children: [
//       {
//         href: '/user-admin/user-amin-list',
//         // icon: UserPlusIcon,
//         title: 'User admin list',
//         isChild: true
//       }
//     ]
//   },
//   {
//     href: '/app/profile',
//     icon: UserIcon,
//     title: 'Profile',
//     component: lazy(() => import('./pages/profile')),
//     layout: '/app'
//   },
//   {
//     href: '/404',
//     icon: AlertCircleIcon,
//     title: 'Error'
//   }
// ];
