import { httpClient } from '../utils/HttpClient';

interface ILogin {
  username: string;
  password: string;
}

const login = ({ username, password }: ILogin) => {
  return httpClient
    .post('/login', {
      username,
      password
    })
    .then((response) => {
      // console.log('response', response);
      // if (response) {
      //   localStorage.setItem('user', JSON.stringify(response));
      // }

      return response;
    });
};

const getCurrentUser = () => {
  return httpClient.get('/api/current-user').then((response) => {
    return response;
  });
};

const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
};

const getCurrentUserProfile = () => {
  // return JSON.parse(localStorage.getItem("user") as any);
  const userInfo = JSON.parse(localStorage.getItem('user') as any);
  // console.log('userInfo', userInfo);
  if (!userInfo) {
    logout();
    return;
  }
  return {
    avatar: '/static/images/avatars/avatar_6.png',
    groupId: userInfo.groupId && userInfo.groupId,
    // name: 'Katarina Smith'
    name: `${userInfo.firstName ? userInfo.firstName : ''} ${
      userInfo.lastName ? userInfo.lastName : ''
    }`
  };
};

const getCurrentUserGroup = () => {
  // return JSON.parse(localStorage.getItem("user") as any);
  const userInfo = JSON.parse(localStorage.getItem('user') as any);
  // console.log('userInfo', userInfo);
  if (!userInfo) {
    logout();
    return;
  }
  return userInfo.groupId;
};

const getCurrentUserId = () => {
  // return JSON.parse(localStorage.getItem("user") as any);
  const userInfo = JSON.parse(localStorage.getItem('user') as any);
  // console.log('userInfo', userInfo);
  if (!userInfo) {
    logout();
    return;
  }
  return userInfo.userId;
};

export default {
  login,
  getCurrentUser,
  logout,
  getCurrentUserProfile,
  getCurrentUserGroup,
  getCurrentUserId
};
