import { httpClient } from '../utils/HttpClient';
import { removeEmptyObject } from '../utils/removeEmptyObject';

const BASE_URL_EXPORT_DRIVING_LESSON =
  '/api/excel/report/excel-application-payment';
const BASE_URL_PREVIEW_EXPORT_DRIVING_LESSON =
  '/api/excel/report/application-payment';

export interface IRecruitmentDrivingExport {
  appDateFrom: number | null;
  appDateTo: number | null;
  appType: string;
  transmissionType: string;
  carType: string;
}

const exportExcelDrivingLesson = (filter: IRecruitmentDrivingExport) => {
  let getFilter = {};
  if (filter) {
    getFilter = removeEmptyObject(filter);
  }
  return httpClient
    .get(`${BASE_URL_EXPORT_DRIVING_LESSON}`, {
      responseType: 'arraybuffer',
      params: { ...getFilter }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const getPreviewExport = (filter: IRecruitmentDrivingExport) => {
  let getFilter = {};
  if (filter) {
    getFilter = removeEmptyObject(filter);
  }
  return httpClient
    .get(`${BASE_URL_PREVIEW_EXPORT_DRIVING_LESSON}`, {
      params: { ...getFilter }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default {
  exportExcelDrivingLesson,
  getPreviewExport
};
