import React, { createContext, useState } from 'react';
import RegisterDrivingForm from '../pages/AppForDrivingLessons/DrivingForm/DrivingFormModel/registerDrivingFormInitialValues';

export const StoreContext = createContext({});

export const StoreContextProvider = ({ children }: any) => {
  // initial State
  const [drivingFormData, setDrivingFormData] = useState(RegisterDrivingForm);
  // console.log('drivingFormData', drivingFormData);

  // value สำหรับ return ไปให้หน้าต่าง ๆ ใช้
  const store = {
    drivingFormStore: [drivingFormData, setDrivingFormData]
  };

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
