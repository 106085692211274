import React, { useState } from 'react';
// import {Link as RouterLink, useHistory} from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  FormHelperText,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import Page from '../../components/page';
// import {httpClient} from "../../utils/HttpClient";
import { AuthService } from '../../services';
import Logo from '../../components/Logo';

const fontMitr = "'Mitr', sans-serif";
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    backgroundColor: '#3753ad',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardBox: {
    display: 'flex',
    padding: 32,
    minHeight: 400,
    flexDirection: 'column'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
    alignItems: 'center'
  },
  titleLogin: {
    marginBottom: theme.spacing(1)
  },
  boxLogoTitle: {
    width: 125,
    height: 60
  },
  logoTitle: {
    // width: 125,
    // height: 60
    width: '100%'
  },
  buttonLogin: {
    fontFamily: fontMitr
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  boxErrorLoginText: {
    marginTop: theme.spacing(2)
  }
}));

const LoginPage = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState<Boolean>(false);
  const [isErrorLogin, setIsErrorLogin] = useState<Boolean>(false);
  const [isErrorNotFoundUser, setIsErrorNotFoundUser] = useState<Boolean>(
    false
  );
  const history = useHistory();

  return (
    <Page className={classes.root} title="Login" showBreadcrumbsBar={false}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Card className={classes.cardBox}>
            <Formik
              initialValues={{
                username: '',
                password: ''
              }}
              validationSchema={Yup.object().shape({
                username: Yup.string()
                  // .email('Must be a valid email')
                  .max(255)
                  .required('กรุณาระบุชื่อผู้ใช้งาน'),
                password: Yup.string().max(255).required('กรุณาระบุรหัสผ่าน')
              })}
              onSubmit={(values, actions) => {
                setLoading(true);
                setIsErrorLogin(false);
                setIsErrorNotFoundUser(false);
                AuthService.login(values).then(
                  (result: any) => {
                    if (result && result.data && result.data.token) {
                      localStorage.setItem('token', result.data.token);
                      AuthService.getCurrentUser().then(
                        (userResult: any) => {
                          console.log(userResult);
                          actions.setSubmitting(false);
                          setLoading(false);
                          localStorage.setItem(
                            'user',
                            JSON.stringify(userResult.data.user)
                          );
                          if (userResult.data.user.groupId === 1) {
                            history.push('/app/app-for-driving-lessons', {
                              replace: true
                            });
                          }
                          if (userResult.data.user.groupId === 2) {
                            history.push('/app/teaching-schedule', {
                              replace: true
                            });
                          }
                          if (userResult.data.user.groupId === 3) {
                            history.push('/app/app-for-driving-lessons', {
                              replace: true
                            });
                          }
                        },
                        () => {
                          setLoading(false);
                          actions.setSubmitting(false);
                          setIsErrorNotFoundUser(true);
                          AuthService.logout();
                        }
                      );
                    }
                  },
                  (error) => {
                    console.log('is error', error);
                    setLoading(false);
                    setIsErrorLogin(true);
                    actions.setSubmitting(false);
                  }
                );
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3} className={classes.titleContainer}>
                    <div>
                      <Typography
                        color="textPrimary"
                        variant="h2"
                        className={classes.titleLogin}
                      >
                        เข้าสู่ระบบ
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                      >
                        ลงชื่อเข้าใช้งานระบบ
                      </Typography>
                    </div>
                    <div className={classes.boxLogoTitle}>
                      <Logo className={classes.logoTitle} />
                    </div>
                  </Box>
                  <TextField
                    error={Boolean(touched.username && errors.username)}
                    fullWidth
                    helperText={touched.username && errors.username}
                    label="Username"
                    margin="normal"
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.username}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  {isErrorLogin && (
                    <div className={classes.boxErrorLoginText}>
                      <FormHelperText error={true}>
                        ชื่อผู้ใช้งาน หรือ รหัสผ่านไม่ถูกต้อง
                      </FormHelperText>
                    </div>
                  )}
                  {isErrorNotFoundUser && (
                    <div className={classes.boxErrorLoginText}>
                      <FormHelperText error={true}>
                        ไม่พบบัญชีผู้ใช้งานของคุณ
                      </FormHelperText>
                    </div>
                  )}
                  <Box my={2}>
                    <div className={classes.wrapper}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        className={classes.buttonLogin}
                      >
                        เข้าสู่ระบบ
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </Box>
                </form>
              )}
            </Formik>
          </Card>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginPage;
