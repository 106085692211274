import React, { useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, List, ListItem, makeStyles } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

const fontMitr = '"Mitr", sans-serif';
const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    // fontWeight: theme.typography.fontWeightMedium,
    fontWeight: 'bold',
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  buttonChild: {
    paddingLeft: '40px'
  },
  icon: {
    marginRight: theme.spacing(1),
    width: '20px'
  },
  title: {
    marginRight: 'auto',
    fontFamily: fontMitr
  },
  titleChild: {
    fontWeight: 400,
    fontFamily: fontMitr
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      // fontWeight: theme.typography.fontWeightMedium
      fontWeight: 'bold'
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

interface IProps {
  className?: string;
  href: string;
  icon: any;
  title: string;
  children?: {
    className?: string;
    href: string;
    icon?: any;
    title: string;
    isChild?: boolean;
  }[];
  isChild?: boolean;
}

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  children,
  isChild,
  ...rest
}: IProps) => {
  const classes = useStyles();
  const location = useLocation();
  // ติด bug set menu ตอนที่ตัวเองเป็น sub
  const [menu, setMenu]: any = useState(
    children && location.pathname.indexOf(href) === 0 ? { [title]: true } : {}
  );
  // console.log('this route', href, window.location.pathname);
  const handleClick = (item: string) => {
    let newData = { ...menu, [item]: !menu[item] };
    setMenu(newData);
    console.log(menu);
  };
  // console.log(menu);
  // console.log('children', children);
  if (children && children.length > 0) {
    return (
      <>
        <ListItem
          className={clsx(classes.item, className)}
          disableGutters
          {...rest}
          onClick={() => handleClick(title)}
        >
          <Button className={classes.button}>
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={classes.title}>{title}</span>
            {menu[title] ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </ListItem>

        <Collapse in={!!menu[title]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                isChild={item.isChild}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  } else {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          className={clsx(classes.button, isChild && classes.buttonChild)}
          component={RouterLink}
          to={href}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={clsx(classes.title, isChild && classes.titleChild)}>
            {title}
          </span>
        </Button>
      </ListItem>
    );
  }
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
