import axios from 'axios';
import join from 'url-join';
import { AuthService } from '../services';

axios.interceptors.request.use(async (config: any) => {
  if (!config.url.includes(process.env.REACT_APP_URL_READ_ID_CARD)) {
    // console.log('is token');
    const apiUrl: string | any = process.env.REACT_APP_API_URL;
    const token = await localStorage.getItem('token');
    if (token != null) {
      config.headers = { Authorization: `Bearer ${token}` };
    }
    config.url = join(apiUrl, config.url);
  }
  // console.log('interceptors config', config);
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log('error', error.response);
    if (
      error &&
      error.response &&
      error.response.status &&
      401 === error.response.status
    ) {
      console.log('error', error.response);
      AuthService.logout();
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  }
);

// axios.interceptors.request.use(async (response: any) => {
//
//   console.log('interceptors response', response);
//   return response;
//
// });
export const httpClient = axios;
