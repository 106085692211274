import { httpClient } from '../utils/HttpClient';
import { removeEmptyObject } from '../utils/removeEmptyObject';

const BASE_URL_COURSE_NAME = 'api/course';
const BASE_URL_COURSE = 'api/course-list';

interface ICourseSelectVal {
  appType: string;
  carType: string;
  gearType: string;
  numHour?: string | number | undefined | null;
}

const getCourseSelect = (param: ICourseSelectVal | null) => {
  return httpClient
    .get(BASE_URL_COURSE_NAME, { params: param })
    .then((response) => {
      return response;
    });
};

export interface IFilterCourse {
  description: string;
  appType: string;
  carType: string;
  gearType: string;
}

const getListCourse = (filter: IFilterCourse) => {
  let getFilter = {};
  if (filter) {
    getFilter = removeEmptyObject(filter);
  }
  return httpClient
    .get(`${BASE_URL_COURSE}`, { params: { ...getFilter } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const getCourseById = (courseId: number) => {
  return httpClient
    .get(`${BASE_URL_COURSE}/${courseId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export interface IDataCourseForm {
  license: string;
  price: string;
  carType: string;
  appType: string;
  gearType: string;
}

const postCourse = (value: IDataCourseForm | undefined) => {
  return httpClient
    .post(BASE_URL_COURSE, value)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const updateCourse = (value: IDataCourseForm | undefined, courseId: number) => {
  return httpClient
    .put(`${BASE_URL_COURSE}/${courseId}`, value)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default {
  getCourseSelect,
  getListCourse,
  getCourseById,
  postCourse,
  updateCourse
};
