import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import LoginPage from '../../pages/authorization/LoginPage';
import NotFoundPage from '../../pages/errorPage/NotFoundPage';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const authRoutes = [
  {
    href: '/authorization/login',
    component: LoginPage,
    layout: '/authorization'
  }
];

const getRoutes = (routes: any) => {
  return routes.map((prop: any, key: any) => {
    if (prop.layout === '/authorization') {
      return (
        <Route
          path={prop.href}
          render={(props) => <prop.component {...props} />}
          key={key}
        />
      );
    } else {
      return null;
    }
  });
};

const AuthorizationLayout = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Switch>
              {getRoutes(authRoutes)}
              <Route component={NotFoundPage} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorizationLayout;
