import React from 'react';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import Page from './page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const ErrorFallbackPageBoundary = ({ error, resetErrorBoundary }: any) => {
  const classes = useStyles();
  console.log('ErrorFallbackPageBoundary', error, resetErrorBoundary);

  return (
    <Page className={classes.root} title="Error" showBreadcrumbsBar={false}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          {error.name === 'ChunkLoadError' ? (
            <Typography align="center" color="textPrimary" variant="h4">
              This application has been updated, please refresh your browser to
              see the latest content.
            </Typography>
          ) : (
            <Typography align="center" color="textPrimary" variant="h4">
              An error has occurred, please refresh and try again.
            </Typography>
          )}
          {/*<Box textAlign="center">*/}
          {/*  <img*/}
          {/*    alt="Under development"*/}
          {/*    className={classes.image}*/}
          {/*    src="/static/images/undraw_page_not_found_su7k.svg"*/}
          {/*  />*/}
          {/*</Box>*/}
        </Container>
      </Box>
    </Page>
  );
};

export default ErrorFallbackPageBoundary;

// const ErrorFallback = ({error, resetErrorBoundary}: any) => {
//   return (
//     <div role="alert">
//       <p>Something went wrong:</p>
//       <pre>{error.message}</pre>
//       <button onClick={resetErrorBoundary}>Try again</button>
//     </div>
//   )
// }
