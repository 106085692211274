import React, { Suspense, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { createStyles } from '@material-ui/styles';
import routes from '../../routes';
import NotFoundPage from '../../pages/errorPage/NotFoundPage';
import ProgressLoadingPage from '../../components/ProgressLoadingPage';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackPageBoundary from '../../components/ErrorFallbackPageBoundary';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    },
    wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: 64,
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 256
      }
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden'
    },
    content: {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto'
    }
  })
);

const getRoutes = (routes: any) => {
  return routes.map((prop: any, key: any) => {
    if (prop.layout === '/app') {
      if (prop.children && prop.children.length > 0) {
        return (
          <Switch key={`children-${key}`}>
            {getRoutes(prop.children)}
            <Route component={NotFoundPage} />
          </Switch>
        );
      } else {
        return (
          <Route
            exact={!!prop.exact}
            path={prop.href}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      }
    } else {
      return null;
    }
  });
};

const DashboardLayout = (props: any) => {
  // console.log('props dashboard', props);
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <ErrorBoundary
              FallbackComponent={ErrorFallbackPageBoundary}
              // onReset={() => {
              // reset the state of your app so the error doesn't happen again
              // }}
            >
              <Suspense fallback={<ProgressLoadingPage />}>
                <Switch>
                  {getRoutes(routes)}
                  <Route component={NotFoundPage} />
                </Switch>
              </Suspense>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
