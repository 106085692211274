import { httpClient } from '../utils/HttpClient';

const BASE_URL_APPLICATION = 'api/application-payment';

const getApplicationPaymentListById = (applicationId: number) => {
  return httpClient
    .get(BASE_URL_APPLICATION, { params: { applicationId: applicationId } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const postApplicationPayment = (value: any) => {
  return httpClient
    .post(BASE_URL_APPLICATION, value)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const getApplicationPaymentSummaryPrint = (paymentId: number | null) => {
  return httpClient
    .get(`${BASE_URL_APPLICATION}-sum/${paymentId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default {
  getApplicationPaymentListById,
  postApplicationPayment,
  getApplicationPaymentSummaryPrint
};
