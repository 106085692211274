import { httpClient } from '../utils/HttpClient';
import { removeEmptyObject } from '../utils/removeEmptyObject';

const BASE_URL_EXPORT_SUMMARY_PAYMENT = '/api/report/excel-summary-payment';
const BASE_URL_SUMMARY_PAYMENT = '/api/report/summary-payment';

export interface IFilterSummaryPayment {
  paymentDateFrom: number | null;
  paymentDateTo: number | null;
  appType: string;
  transmissionType: string;
  carType: string;
}

const exportExcelSummaryPayment = (filter: IFilterSummaryPayment) => {
  let getFilter = {};
  if (filter) {
    getFilter = removeEmptyObject(filter);
  }
  return httpClient
    .get(`${BASE_URL_EXPORT_SUMMARY_PAYMENT}`, {
      responseType: 'arraybuffer',
      params: { ...getFilter }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const getSummaryPayment = (filter: IFilterSummaryPayment) => {
  let getFilter = {};
  if (filter) {
    getFilter = removeEmptyObject(filter);
  }
  return httpClient
    .get(`${BASE_URL_SUMMARY_PAYMENT}`, {
      params: { ...getFilter }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default {
  exportExcelSummaryPayment,
  getSummaryPayment
};
