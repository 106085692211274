import { httpClient } from '../utils/HttpClient';

const BASE_URL_MONTHLY_REPORT_ADMIN = 'api/admin/report/monthly-report-admin';

interface IFilterSummary {
  scheduleDateFrom: number | null;
  scheduleDateTo: number | null;
  carType: string;
}

const getMonthlyReportAdmin = (filterParam: IFilterSummary) => {
  return httpClient
    .get(BASE_URL_MONTHLY_REPORT_ADMIN, { params: { ...filterParam } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default {
  getMonthlyReportAdmin
};
