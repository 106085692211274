import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import NavItem from './NavItem';
import AuthService from '../../../services/auth.service';
import navbarMenu from './navbarMenu';
import enumTypeToDisplay from '../../../utils/enumTypeToDisplay';

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  boxUserDetail: {
    marginTop: 16,
    textAlign: 'center'
  }
}));

const NavBar = ({
  onMobileClose,
  openMobile
}: {
  onMobileClose: any;
  openMobile: any;
}) => {
  const classes = useStyles();
  const location = useLocation();

  const userGroupId = AuthService.getCurrentUserGroup();
  const items = navbarMenu.filter((item) => item.groupId.includes(userGroupId));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const user = AuthService.getCurrentUserProfile();

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user?.avatar}
          to="/app/account"
        />
        <div className={classes.boxUserDetail}>
          <Typography color="textPrimary" variant="h5">
            {user?.name}
          </Typography>
          {user?.groupId && (
            <Typography color="textSecondary" variant="body2">
              {enumTypeToDisplay.groupIdToText(user?.groupId)}
            </Typography>
          )}
        </div>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              children={item.children}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box p={2} m={2} bgcolor="background.dark"></Box>
    </Box>
  );

  return (
    <>
      {/* @ts-ignore */}
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      {/* @ts-ignore */}
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
