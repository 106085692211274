import { colors, createMuiTheme } from '@material-ui/core';
import { typographySetting } from './typography';
import { shadowsSetting } from './shadows';

const fontMitr = '"Mitr", sans-serif';

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#F4F6F8'
      // paper: colors.common.white
    },
    primary: {
      // main: colors.indigo[500]
      main: '#3753ad'
    },
    secondary: {
      // main: colors.indigo[500]
      main: '#3753ad'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  overrides: {
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: 'green'
        }
      }
    },
    MuiDialogTitle: {
      root: {
        '& h2': {
          fontSize: '1.25rem',
          fontFamily: fontMitr
        }
      }
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 16
        }
      }
    }
  },
  shadows: shadowsSetting,
  typography: typographySetting
});

export default theme;
