import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import BreadcrumbsBar from './Breadcrumbs';

type Props = { [propName: string]: {} };
export type Ref = HTMLDivElement;

const Page = forwardRef<Ref, any>(
  ({ children, title = '', showBreadcrumbsBar = true, ...rest }, ref) => {
    return (
      <div ref={ref} {...rest}>
        {/* @ts-ignore */}
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {showBreadcrumbsBar && <BreadcrumbsBar />}
        {children}
      </div>
    );
  }
);

// Page.propTypes = {
//   children: PropTypes.node.isRequired,
//   title: PropTypes.string
// };

export default Page;
