import { httpClient } from '../utils/HttpClient';
import { IApplicationSchedule } from './models';
import { removeEmptyObject } from '../utils/removeEmptyObject';

const BASE_URL_APPLICATION_SCHEDULE = 'api/application-schedule';
const BASE_URL_APPLICATION_SCHEDULE_TABLE = 'api/application-table';
const BASE_URL_APPLICATION_SCHEDULE_REPORT = 'api/excel/report/schedule-report';
const BASE_URL_OPERATOR_CHECK = 'api/operator-check';

const postApplicationSchedule = (value: IApplicationSchedule[]) => {
  return httpClient
    .post(BASE_URL_APPLICATION_SCHEDULE, value)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const putApplicationSchedule = (value: IApplicationSchedule[]) => {
  return httpClient
    .put(BASE_URL_APPLICATION_SCHEDULE, value)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const deleteApplicationSchedule = (
  applicationScheduleId: string | number | undefined
) => {
  return httpClient
    .delete(`${BASE_URL_APPLICATION_SCHEDULE}/${applicationScheduleId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const getScheduleByApplicationId = (appId: any) => {
  return httpClient
    .get(BASE_URL_APPLICATION_SCHEDULE, { params: { applicationId: appId } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const getScheduleByAppValidateInstructors = (filterParam: any) => {
  return httpClient
    .post(`${BASE_URL_APPLICATION_SCHEDULE}-validate`, filterParam)
    .then((response) => {
      const mapInstructorsOption: any[] | PromiseLike<any[]> = [];
      if (
        response &&
        response.data.instructors &&
        response.data.instructors.length > 0
      ) {
        response.data.instructors.forEach((item: any) => {
          mapInstructorsOption.push({
            value: item.userId,
            label: item.firstName + ' ' + item.lastName
          });
        });
      }
      return mapInstructorsOption;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const getScheduleByAppValidateCar = (filterParam: any) => {
  return httpClient
    .post(`${BASE_URL_APPLICATION_SCHEDULE}-validate`, filterParam)
    .then((response) => {
      const mapCarOption: any[] | PromiseLike<any[]> = [];
      if (response && response.data.cars && response.data.cars.length > 0) {
        response.data.cars.forEach((item: any) => {
          mapCarOption.push({
            value: item.id,
            label: item.license
          });
        });
      }
      return mapCarOption;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

interface IParamGetAppScheduleTable {
  dateFrom: number;
  dateTo: number;
  carType: string;
  gearType: 'AUTO' | 'MANUAL' | undefined;
}

const getApplicationScheduleTable = (
  appDrivingId: string | number,
  params: IParamGetAppScheduleTable
) => {
  return httpClient
    .get(`${BASE_URL_APPLICATION_SCHEDULE_TABLE}/${appDrivingId}`, {
      params: { ...params }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

interface IExportExcelScheduleDay {
  carType: 'CAR' | 'MOTORCYCLES' | 'CAR_SUB_STUDY' | 'MOTORCYCLES_SUB_STUDY';
  scheduleDate: number;
}

const exportExcelScheduleDay = (filter: IExportExcelScheduleDay) => {
  let getFilter = {};
  if (filter) {
    getFilter = removeEmptyObject(filter);
  }
  return httpClient
    .get(`${BASE_URL_APPLICATION_SCHEDULE_REPORT}`, {
      responseType: 'arraybuffer',
      params: { ...getFilter }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const putOperatorCheck = (value: {
  operatorCheckStatus: boolean;
  appScheduleIdList: number[];
}) => {
  return httpClient
    .put(BASE_URL_OPERATOR_CHECK, value)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default {
  postApplicationSchedule,
  putApplicationSchedule,
  deleteApplicationSchedule,
  getScheduleByApplicationId,
  getScheduleByAppValidateInstructors,
  getScheduleByAppValidateCar,
  getApplicationScheduleTable,
  exportExcelScheduleDay,
  putOperatorCheck
};
