import { httpClient } from '../utils/HttpClient';

const BASE_URL_PROVINCE = 'api/districts';

const getTambonOption = (amphoeId: number) => {
  return httpClient
    .get(BASE_URL_PROVINCE, { params: { amphureId: amphoeId } })
    .then((response) => {
      // console.log('tambon', response);
      const mapTambonOption: any[] | PromiseLike<any[]> = [];
      if (response && response.data.length > 0) {
        response.data.forEach((item: any) => {
          mapTambonOption.push({
            value: item.id,
            label: item.nameTh,
            zipCode: item.zipCode
          });
        });
      }
      return mapTambonOption;
    });
};

export default {
  getTambonOption
};
