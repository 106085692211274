import { httpClient } from '../utils/HttpClient';

const BASE_URL_PROVINCE = 'api/amphures';

const getAmphoeOption = (provinceId: number) => {
  return httpClient
    .get(BASE_URL_PROVINCE, { params: { provinceId: provinceId } })
    .then((response) => {
      // console.log('amphoe', response);
      const mapAmphoeOption: any[] | PromiseLike<any[]> = [];
      if (response && response.data.length > 0) {
        response.data.forEach((item: any) => {
          mapAmphoeOption.push({
            value: item.id,
            label: item.nameTh
          });
        });
      }
      return mapAmphoeOption;
    });
};

export default {
  getAmphoeOption
};
