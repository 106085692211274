import { httpClient } from '../utils/HttpClient';
import { removeEmptyObject } from '../utils/removeEmptyObject';

const BASE_URL_CAR = 'api/car';
const BASE_URL_CAR_PAGIN = 'api/car-paging';

interface IFilterTimeSlot {
  carType: string;
  status: string;
}

const getCarOption = (param: IFilterTimeSlot) => {
  return httpClient.get(BASE_URL_CAR, { params: param }).then((response) => {
    const mapMasterCarOption: any[] | PromiseLike<any[]> = [];
    if (response && response.data.length > 0) {
      response.data.forEach((item: any) => {
        mapMasterCarOption.push({
          value: item.id,
          label: item.license
        });
      });
    }
    return mapMasterCarOption;
  });
};

interface IFilterCarList {
  license: string;
  carType: string;
}

interface IPagination {
  page: number;
  size: number;
}

const getListCar = (param: IPagination, filter: IFilterCarList) => {
  let getFilter = {};
  if (filter) {
    getFilter = removeEmptyObject(filter);
  }
  const setParam = {
    page: param.page,
    size: param.size,
    ...getFilter
  };
  return httpClient
    .get(`${BASE_URL_CAR_PAGIN}`, { params: { ...setParam } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const getCarById = (carId: number) => {
  return httpClient
    .get(`${BASE_URL_CAR}/${carId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export interface IDataCarForm {
  license: string;
  carType: string;
}

const postCar = (value: IDataCarForm | undefined) => {
  return httpClient
    .post(BASE_URL_CAR, value)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const updateCar = (value: IDataCarForm | undefined, carId: number) => {
  return httpClient
    .put(`${BASE_URL_CAR}/${carId}`, value)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const deleteCar = (id: number) => {
  return httpClient
    .delete(`${BASE_URL_CAR}/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default {
  getCarOption,
  getListCar,
  deleteCar,
  postCar,
  updateCar,
  getCarById
};
