import React from 'react';
import { Box, Container, LinearProgress, Theme } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';

const BorderLinearProgress = withStyles(() =>
  createStyles({
    bar: {
      backgroundColor: '#3753ad'
    }
  })
)(LinearProgress);

const ProgressLoadingPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Container maxWidth="sm">
        <BorderLinearProgress />
      </Container>
    </Box>
  );
};

export default ProgressLoadingPage;
