import { removeEmptyObject } from '../utils/removeEmptyObject';
import { httpClient } from '../utils/HttpClient';

const BASE_URL_SUMMARY_HOURS_INSTRUCTOR =
  '/api/admin/report/summary-hours-instructor';
const BASE_URL_SUMMARY_HOURS_INSTRUCTOR_EXPORT =
  '/api/excel/report/excel-summary-hours-instructor';

export interface IFilterSummaryHoursInstructor {
  instructorId?: number | string | null | undefined;
  scheduleDateFrom?: number | null | undefined;
  scheduleDateTo?: number | null | undefined;
}

const getSummaryHoursInstructorList = (
  filter: IFilterSummaryHoursInstructor
) => {
  let getFilter = {};
  if (filter) {
    getFilter = removeEmptyObject(filter);
  }
  return httpClient
    .get(`${BASE_URL_SUMMARY_HOURS_INSTRUCTOR}`, {
      params: { ...getFilter }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const exportExcelSummaryHour = (filter: IFilterSummaryHoursInstructor) => {
  let getFilter = {};
  if (filter) {
    getFilter = removeEmptyObject(filter);
  }
  return httpClient
    .get(`${BASE_URL_SUMMARY_HOURS_INSTRUCTOR_EXPORT}`, {
      responseType: 'arraybuffer',
      params: { ...getFilter }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default {
  getSummaryHoursInstructorList,
  exportExcelSummaryHour
};
