// import React from 'react';
// import { Route } from 'react-router-dom';

// interface RouteI {
//   path: string;
//   element: any;
//   children?: {
//     path: string;
//     element: any;
//   }[]
// }

// import { User as UserIcon } from 'react-feather';
import { lazy } from 'react';

// groupId 1 = admin
// groupId 2 = teacher

const routes: any[] = [
  {
    href: '/app/app-for-driving-lessons',
    title: 'ใบสมัคร',
    component: lazy(() => import('./pages/AppForDrivingLessons')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1
  },
  {
    href: '/app/app-for-driving-lessons/create-driving-lessons-form',
    title: 'สร้างใบสมัคร',
    component: lazy(() => import('./pages/AppForDrivingLessons/DrivingForm')),
    layout: '/app',
    showSidebar: false,
    groupId: 1
  },
  {
    href: '/app/app-for-driving-lessons/driving-lessons-detail/:appDrivingId',
    title: 'รายละเอียดการสมัคร',
    component: lazy(
      () => import('./pages/AppForDrivingLessons/DrivingLessonsDetail')
    ),
    layout: '/app',
    showSidebar: false,
    groupId: 1
  },
  {
    href:
      '/app/app-for-driving-lessons/schedule-motor/:appDrivingId/:transmissionType',
    title: 'ลงข้อมูลตารางเรียน (จักรยานยนต์)',
    component: lazy(
      () => import('./pages/AppForDrivingLessons/AppSchedule/AppScheduleMotor')
    ),
    layout: '/app',
    showSidebar: false,
    groupId: 1
  },
  {
    href:
      '/app/app-for-driving-lessons/schedule-car/:appDrivingId/:transmissionType',
    title: 'ลงข้อมูลตารางเรียน (รถยนต์)',
    component: lazy(
      () => import('./pages/AppForDrivingLessons/AppSchedule/AppScheduleCar')
    ),
    layout: '/app',
    showSidebar: false,
    groupId: 1
  },
  {
    href: '/app/teacher-schedule-list',
    title: 'ตารางการเรียนการสอน',
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1,
    isParent: true
  },
  {
    href: '/app/teacher-schedule-list/car',
    title: 'รถยนต์',
    component: lazy(
      () => import('./pages/TeacherScheduleList/TeacherScheduleCar')
    ),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1,
    isChild: true,
    childOf: '/app/teacher-schedule-list'
  },
  {
    href: '/app/teacher-schedule-list/sub-study-car',
    title: 'เรียนเสริมรถยนต์',
    component: lazy(
      () => import('./pages/TeacherScheduleList/TeacherScheduleCarSubStudy')
    ),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1,
    isChild: true,
    childOf: '/app/teacher-schedule-list'
  },
  {
    href: '/app/teacher-schedule-list/motor',
    title: 'รถจักรยานยนต์',
    component: lazy(
      () => import('./pages/TeacherScheduleList/TeacherScheduleMotor')
    ),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1,
    isChild: true,
    childOf: '/app/teacher-schedule-list'
  },
  {
    href: '/app/teacher-schedule-list/sub-study-motor',
    title: 'เรียนเสริมรถจักรยานยนต์',
    component: lazy(
      () => import('./pages/TeacherScheduleList/TeacherScheduleMotorSubStudy')
    ),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1,
    isChild: true,
    childOf: '/app/teacher-schedule-list'
  },
  {
    href: '/app/teaching-schedule',
    title: 'ตารางสอน',
    component: lazy(() => import('./pages/TeachingSchedule')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 2
  },
  {
    href: '/app/monthly-summary',
    title: 'Monthly Summary',
    component: lazy(() => import('./pages/MonthlySummary')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 2
  },
  {
    href: '/app/master-data',
    title: 'ข้อมูลหลัก',
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1,
    isParent: true
  },
  {
    href: '/app/master-data/car-list',
    title: 'ข้อมูลรถ',
    component: lazy(() => import('./pages/masterData/CarList')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1
  },
  {
    href: '/app/master-data/car-list/new',
    title: 'เพิ่มรถ',
    component: lazy(() => import('./pages/masterData/CarList/CarForm')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1
  },
  {
    href: '/app/master-data/car-list/:carId/edit',
    title: 'แก้ไขรถ',
    component: lazy(() => import('./pages/masterData/CarList/CarForm')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1
  },
  {
    href: '/app/master-data/course-list',
    title: 'ข้อมูลหลักสูตร',
    component: lazy(() => import('./pages/masterData/CourseList')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1
  },
  {
    href: '/app/master-data/course-list/:courseId/edit',
    title: 'แก้ไขหลักสูตร',
    component: lazy(() => import('./pages/masterData/CourseList/CourseForm')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1
  },
  {
    href: '/app/exam-schedule',
    title: 'ตารางสอบ',
    component: lazy(() => import('./pages/ExamSchedule')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1
  },
  {
    href: '/app/report',
    title: 'รายงาน',
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1,
    isParent: true
  },
  {
    href: '/app/report/recruitment-driving-lesson-report',
    title: 'รายงานการรับสมัคร',
    component: lazy(
      () => import('./pages/report/RecruitmentDrivingLessonReport')
    ),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1
  },
  {
    href: '/app/report/payment-report',
    title: 'รายงานการชำระเงิน',
    component: lazy(() => import('./pages/report/PaymentReport')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1
  },
  {
    href: '/app/report/teaching-hours-report',
    title: 'รายงานจำนวนชั่วโมงสอน',
    component: lazy(() => import('./pages/report/TeachingHoursReport')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1
  },
  {
    href: '/app/user-list',
    title: 'รายชื่อผู้ใช้',
    component: lazy(() => import('./pages/UserList')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1
  },
  {
    href: '/app/user-list/new',
    title: 'เพิ่มผู้ใช้งาน',
    component: lazy(() => import('./pages/UserList/UserForm')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1
  },
  {
    href: '/app/user-list/:userId/edit',
    title: 'แก้ไขผู้ใช้งาน',
    component: lazy(() => import('./pages/UserList/UserForm')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1
  },
  {
    href: '/app/user-list/:userId/detail',
    title: 'ข้อมูลผู้ใช้งาน',
    component: lazy(() => import('./pages/UserList/UserDetail')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1
  },
  {
    href: '/app/profile',
    title: 'ข้อมูลส่วนตัว',
    component: lazy(() => import('./pages/Profile')),
    layout: '/app',
    showSidebar: true,
    exact: true,
    groupId: 1
  }
];

export default routes;

// const routes: any[] = [
//   {
//     href: '/app/dashboard',
//     icon: BarChartIcon,
//     title: 'Dashboard'
//   },
//   {
//     href: '/app/customers',
//     icon: UsersIcon,
//     title: 'Customers'
//   },
//   {
//     href: '/app/products',
//     icon: ShoppingBagIcon,
//     title: 'Products'
//   },
//   {
//     href: '/app/account',
//     icon: UserIcon,
//     title: 'Account'
//   },
//   {
//     href: '/app/settings',
//     icon: SettingsIcon,
//     title: 'Settings'
//   },
//   {
//     href: '/login',
//     icon: LockIcon,
//     title: 'Login'
//   },
//   {
//     href: '/register',
//     icon: UserPlusIcon,
//     title: 'Register'
//   },
//   {
//     href: '/app/user-manage',
//     icon: UserPlusIcon,
//     title: 'User manage',
//     children: [
//       {
//         href: '/app/user-manage/user-list',
//         // icon: UserPlusIcon,
//         title: 'User list',
//         isChild: true
//       }
//     ]
//   },
//   {
//     href: '/user-admin',
//     icon: UserPlusIcon,
//     title: 'User admin',
//     children: [
//       {
//         href: '/user-admin/user-amin-list',
//         // icon: UserPlusIcon,
//         title: 'User admin list',
//         isChild: true
//       }
//     ]
//   },
//   {
//     href: '/app/profile',
//     icon: UserIcon,
//     title: 'Profile',
//     component: lazy(() => import('./pages/profile')),
//     layout: '/app'
//   },
//   {
//     href: '/404',
//     icon: AlertCircleIcon,
//     title: 'Error'
//   }
// ];
