export default {
  formId: 'registerDrivingForm',
  formField: {
    appType: {
      name: 'appType',
      label: 'ชนิดการสมัคร',
      requiredErrorMsg: 'กรุณาเลือกชนิดการสมัคร'
    },
    applyThrough: {
      name: 'applyThrough',
      label: 'สมัครผ่าน',
      requiredErrorMsg: 'กรุณาเลือกสมัครผ่าน'
    },
    carType: {
      name: 'carType',
      label: 'ประเภทรถ',
      requiredErrorMsg: 'กรุณาเลือกประเภทรถ'
    },
    transmissionType: {
      name: 'transmissionType',
      label: 'ประเภทเกียร์',
      requiredErrorMsg: 'กรุณาเลือกประเภทเกียร์'
    },
    prefixName: {
      name: 'prefixName',
      label: 'คำนำหน้า *',
      requiredErrorMsg: 'กรุณาระบุคำนำหน้า'
    },
    firstName: {
      name: 'firstName',
      label: 'ชื่อ *',
      requiredErrorMsg: 'กรุณาระบุชื่อ'
    },
    lastName: {
      name: 'lastName',
      label: 'นามสกุล *',
      requiredErrorMsg: 'กรุณาระบุนามสกุล'
    },
    nickName: {
      name: 'nickName',
      label: 'ชื่อเล่น',
      requiredErrorMsg: 'กรุณาระบุชื่อเล่น'
    },
    age: {
      name: 'age',
      label: 'อายุ *',
      requiredErrorMsg: 'กรุณาระบุอายุ'
    },
    birthOfDate: {
      name: 'birthOfDate',
      label: 'วันเดือนปีเกิด *',
      requiredErrorMsg: 'กรุณาระบุวันเดือนปีเกิด',
      invalidErrorMsg: 'รูปแบบวันที่ไม่ถูกต้อง'
    },
    idCardNumber: {
      name: 'idCardNumber',
      label: 'รหัสบัตรประชาชน / หนังสือเดินทาง *',
      requiredErrorMsg: 'กรุณาระบุรหัสบัตรประชาชน / หนังสือเดินทาง',
      invalidErrorMsg: 'กรุณาระบุรหัสบัตรประชาชนให้ถูกต้อง'
    },
    houseNumber: {
      name: 'houseNumber',
      label: 'บ้านเลขที่ *',
      requiredErrorMsg: 'กรุณาระบุบ้านเลขที่'
    },
    moo: {
      name: 'moo',
      label: 'หมู่ที่',
      requiredErrorMsg: 'กรุณาระบุหมู่ที่'
    },
    road: {
      name: 'road',
      label: 'ถนน',
      requiredErrorMsg: 'กรุณาระบุถนน'
    },
    province: {
      name: 'province',
      label: 'จังหวัด *',
      requiredErrorMsg: 'กรุณาระบุจังหวัด'
    },
    amphoe: {
      name: 'amphoe',
      label: 'อำเภอ *',
      requiredErrorMsg: 'กรุณาระบุอำเภอ'
    },
    tambon: {
      name: 'tambon',
      label: 'ตำบล *',
      requiredErrorMsg: 'กรุณาระบุตำบล'
    },
    zipCode: {
      name: 'zipCode',
      label: 'รหัสไปรษณีย์ *',
      requiredErrorMsg: 'กรุณาระบุรหัสไปรษณีย์',
      invalidErrorMsg: 'รูปแบบรหัสไปรษณีย์ไม่ถูกต้อง'
    },
    mobileNumber: {
      name: 'mobileNumber',
      label: 'เบอร์มือถือ *',
      requiredErrorMsg: 'กรุาระบุเบอร์มือถือ'
    },
    email: {
      name: 'email',
      label: 'E-mail',
      invalidErrorMsg: 'รูปแบบ Email ไม่ถูกต้อง'
    },
    idLine: {
      name: 'idLine',
      label: 'ID Line'
    },
    contractFullName: {
      name: 'contractFullName',
      label: 'ชื่อ-สกุล'
    },
    contractMobileNumber: {
      name: 'contractMobileNumber',
      label: 'เบอร์มือถือ'
    },
    // สำรหับหน้าบันทึกข้อมูลชำระเงิน
    courseName: {
      name: 'courseName',
      label: 'ชื่อ Course'
    },
    totalPrice: {
      name: 'totalPrice',
      label: 'ราคารวม'
    },
    discount: {
      name: 'discount',
      label: 'ส่วนลด'
    },
    paymentMethod: {
      name: 'paymentMethod',
      label: 'ช่องทางการชำระ',
      requiredErrorMsg: 'กรุณาระบุช่องทางการชำระ'
    },
    amountPaid: {
      name: 'amountPaid',
      label: 'จำนวนเงินที่ชำระ',
      requiredErrorMsg: 'กรุณาระบุจำนวนเงินที่ชำระ'
    },
    image: {
      fileName: {
        name: 'fileName',
        label: 'ชื่อรูป'
      },
      fileData: {
        name: 'fileData',
        label: 'ไฟล์รูป'
      }
    },
    courseHour: {
      name: 'courseHour',
      label: 'ชั่วโมงเรียน',
      requiredErrorMsg: 'กรุาระบุเลือกชั่วโมงเรียน'
    }
  }
};
