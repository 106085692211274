import { httpClient } from '../utils/HttpClient';
import axios from 'axios';

const BASE_URL_READ_ID_CARD: string | any =
  process.env.REACT_APP_URL_READ_ID_CARD;

let cancelTokenSource = axios.CancelToken.source();

const getStimulateReadIdCard = () => {
  return httpClient
    .get(BASE_URL_READ_ID_CARD)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const stopClientReadIdCard = () => {
  return httpClient
    .get(`${BASE_URL_READ_ID_CARD}/exit`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

const readIdCard = () => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel();
  }
  cancelTokenSource = axios.CancelToken.source();
  return httpClient
    .get(`${BASE_URL_READ_ID_CARD}/getdata`, {
      cancelToken: cancelTokenSource.token
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default {
  readIdCard,
  stopClientReadIdCard,
  getStimulateReadIdCard,
  cancelTokenSource
};
